import { TopBrandModel } from "./topBrandModel";

export class SecondUser {
    public firstname!: string;
    public lastname!: string;
    public email!: string;
    public oldemail!: string;
    public phone!: string;
    public role!: string|null;
    public companyType!: number;
    public companyName!: string;
    public firstnameOfCompanyAdmin!: string;
    public topBrands!: TopBrandModel[];
    public userEmail!: string;

    public constructor() {
        this.firstname = "";
        this.lastname = "";
        this.email = "";
        this.phone = "";
        this.role = "";
        this.companyType = 0;
        this.companyName = "";
        this.firstnameOfCompanyAdmin = "";
        this.topBrands =  [];
        this.userEmail = "";
    }
}

export class SecondUserRequest {
    public userId?: string|null;
    public firstname!: string;
    public lastname!: string;
    public phone!: string;
    public role!: string|null;
    public companyType!: number;
    public companyName!: string;
    public firstnameOfCompanyAdmin!: string;
    public topBrands!: TopBrandModel[];
    public userEmail!: string;

    public constructor() {
        this.firstname = "";
        this.lastname = "";
        this.phone = "";
        this.role = "";
        this.companyType = 0;
        this.companyName = "";
        this.firstnameOfCompanyAdmin = "";
        this.topBrands =  [];
        this.userEmail = "";
    }
}

export class ApprovePendingUserModel{
    public username!: string;
    public firstname!: string;
    public lastname!: string;
    public email!: string;
    public phone!: string;
    public role!: string;
    public companyType!: number;
    public companyName!: string;
    public firstnameOfCompanyAdmin!: string;
    public userId!: string;
    public topBrands!: TopBrandModel[];
}

export class RejectPendingUserModel{
    public SecondUserId!: string;
    public SecondUserName!: string;
    public SecondUserEmail!: string;
    public LoggedInAdminName!: string;
    public InvitedByCompanyId!: string;
    public InvitedByCompanyEmail!: string;
    public InvitedByCompanyName!: string;
  }