import { Vue, Component, Watch } from "vue-property-decorator";
import i18n from '@/i18n';
import store from '@/store';

// Components
import CompanyForm from '@/views/profile/components/companyForm.vue';
import MyDetails from '@/views/profile/components/myDetails.vue';
import AdminSettings from '@/views/profile/components/adminSettings.vue';
import InviteModal from '@/views/profile/components/inviteModal.vue';

// Models
import { UserModel } from '@/models/userModel';
import { triggerThreadSafeValidation } from "vee-validate/dist/types/components/common";
import { CompanyModel } from "@/models/companyModel";
import { SecondUser } from "@/models/secondUser";

@Component({
  components: { CompanyForm, MyDetails, AdminSettings, InviteModal }
})
export default class Profile extends Vue {

  private tabIndex: number = 0;

  private changedSettingsCounter: number = 0;

  private myDetailsChangesCounter: number = 0;
  private showInviteModal: boolean = false;
  private userListFilter: string = "";
  private reloadList: boolean = false;
  private isAdmin: boolean = false;
  private user: UserModel = new UserModel();
  private showEdit: boolean = false;
  private showAdmin: boolean = false;
  private userType: string = "";

  private valid: boolean = true;
  private isMaxAdmins: boolean = false;
  private showMaxAdminsWaring: boolean = false;
  private enteredDetails: SecondUser = new SecondUser();
  private invitationId: string | null = '';

  public constructor() {
    super();
  }

  public async created(): Promise<void> {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    if (this.$route.params.selectTab == 'companydetails') {
      this.selectedTab(1);
    }
    this.invitationId = localStorage.getItem("invitationId");
  }

  private get isAdminUser(): boolean {
    const company = this.$store.getters.company as CompanyModel;
    const user = this.$store.getters.user as UserModel;
    if (company && user) {
      if(user.isAdmin === "true"){
        setTimeout((func: any) => {
          func.redirectAdminSettingHandler();
        }, 500, this);
        return true;
      }
      else{
        const userRole = this.$store.getters.userRole;
        this.userType = user !== null ? user.userType : "";
        this.isAdmin = userRole=="Admin"?true:false;
        if (this.isAdmin && this.userType=='COMPANY_ADMIN' && company.contact.email.toLowerCase()===user.email.toLowerCase()) {
          setTimeout((func: any) => {
            func.redirectAdminSettingHandler();
          }, 500, this);
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  private get title(): string[] {
    return [
      i18n.t('pages.profile.my_details').toString(),
      i18n.t('pages.profile.company_details').toString(),
      i18n.t('pages.profile.admin_details').toString()
    ];
  }

  private selectedTab(index: number): void {
    this.tabIndex = index;
  }

  private enableTab(valid: boolean): void {
    this.valid = valid;
  }

  private setChangedSettingsCounter(value: number): void {
    this.changedSettingsCounter = value;
    if (value>0) {
      store.commit("setCanNavigate", false);
    } else {
      store.commit("setCanNavigate", true);
    }
  }

  private openInviteModal(): void {
    this.showInviteModal = true;
  }

  private closeInviteModal(): void {
    this.showInviteModal = false;
    this.user = new UserModel();
    this.showEdit = false;
    this.enteredDetails = new SecondUser();
  }

  private reload(): void{
    this.reloadList = true;
  }

  private reloadSuccess(): void{
    this.reloadList = false;
  }

  private showEditModal(user: UserModel, isMaxAdmins:boolean): void{
    this.user = user;
    this.showEdit = true;
    this.showInviteModal = true;
    this.isMaxAdmins = isMaxAdmins;
  }

  private redirectAdminSettingHandler(): void {
    if (this.$route.params.admin == '1') {
      this.selectedTab(2);
    }
  }

  private showWarningModal(enteredDetails:SecondUser): void{
    this.showInviteModal = false;
    this.showMaxAdminsWaring = true;
    this.enteredDetails = enteredDetails;
    this.enteredDetails.role = '';
  }

  private backToSetUser(): void{
    this.showMaxAdminsWaring = false;
    if(this.showEdit){
      this.showEditModal(this.user, this.isMaxAdmins);
    }else{
      this.openInviteModal();
    }
  }

  private setMaxAdmins(isMaxAdmins: boolean): void{
    this.isMaxAdmins = isMaxAdmins;
  }

  @Watch('userListFilter')
    private async onUserListFilterChanged(userListFilter: string): Promise<void> {
    }
}